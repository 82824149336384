/* Third party style */
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/dropdown';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './abstracts/variables';
@import './abstracts/mixins';
@import './abstracts/animation';

/* project css */
@import './components/layout.scss';
@import './components/home.scss';
@import './components/services.scss';
@import './components/industry.scss';
@import './components/contact.scss';

@import './common.scss';
