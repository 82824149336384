.contact-form {
  padding: 0 0 2rem 0;
  .input-field {
    background-color: $input-bg;
    border: none;
    padding: 10px 10px;
    border-radius: 7px;
    width: 100%;
  }
  .PhoneInput {
    background-color: $input-bg;
    border-radius: 7px;
    .PhoneInputCountry {
      padding: 7px;
      border-right: 1px solid $grey--eight;
    }
    input {
      padding: 10px 10px;
      border: none;
      background-color: transparent;
    }
  }
  .label {
    color: $orange;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
  }
  .blue-bg-button {
    margin-top: 10px;
    min-width: 150px;
  }
  .item-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .opt-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 8px;
    padding: 0 30px 15px 30px;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $orange;
    cursor: pointer;
    &.active {
      background: #18176e;
      color: $white;
    }
    .orange-line {
      width: 60%;
      height: 4px;
      border-top: 4px solid $orange;
      margin-bottom: 15px;
    }
  }
}
