header {
  background: transparent;
  .header-content {
    padding: 7px 0;
    background: transparent;
    .container-lg {
      @include respond(extra-large-mobile) {
        justify-content: space-between;
      }
    }
    .mobile-menu-icon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .header-content__logo {
      img {
        height: 4rem;
      }
    }
    &.is-sticky {
      position: fixed;
      width: 100vw;
      z-index: 999;
      top: 0;
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0 9px 7px -7px rgba(65, 71, 108, 0.2);
    }
  }
  .header-top {
    padding: 7px 0;
    border-bottom: 1px solid $orange;
    svg {
      color: $orange;
      margin-right: 5px;
    }
    a {
      text-decoration: none;
      &:hover {
        span {
          color: $orange;
        }
      }
    }
    span {
      color: $button-text;
    }
  }
  .header-menu-items-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    flex: 1;
    .dropdown-toggle {
      &.show {
        color: $orange;
        border-bottom: 2px solid $orange;
      }
    }
    .dropdown-menu.show {
      margin-top: 5px;
      box-shadow: 0px 14px 41px #00000029;
      border: none;
      height: calc(100vh - 80px);
      overflow: auto;
      .row {
        min-width: 700px;
      }
    }
    a {
      font-weight: 500;
      color: $button-text;
      text-decoration: none;
      &:hover {
        color: $orange;
      }
      &.active {
        color: $orange;
        border-bottom: 2px solid $orange;
      }
    }
    .service-list-wrapper {
      width: max-content;
      padding: 10px;
      a {
        &:hover {
          p {
            color: $orange;
          }
        }
      }

      ul {
        padding-left: 0;
        width: max-content;
        list-style: none;
        li:before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 10px;
          padding-right: 8px;
          background: $orange;
          margin-bottom: 2px;
        }
        li {
          font-size: 15px;
          color: $button-text;
          line-height: 20px;
          width: max-content;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.offcanvas-mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  visibility: hidden;
  width: 100vw;
  height: 100%;
  transition: $transition--default;
  opacity: 0;
  &.active {
    visibility: visible;
    opacity: 1;
    .offcanvas-mobile-menu__wrapper {
      transform: translateX(0);
    }
    .offcanvas-mobile-menu__overlay-close {
      visibility: visible;
      opacity: 1;
    }
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    content: '';
    transition: 0.3s;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &__overlay-close {
    z-index: 9997;
    visibility: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  &__wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    width: 70vw;
    height: 100vh;
    transition: 0.6s;
    transform: translateX(100%);
  }
  &__close {
    font-size: 30px;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0px;
    width: 60px;
    padding: 0;
    text-align: center;
    color: $text-color;
    border: none;
    background: $white;
    &:hover,
    &:focus {
      color: $text-color;
    }
    svg {
      font-size: 40px;
      line-height: 1;
    }
  }
  &__content-wrapper {
    overflow: auto;
    height: 100%;
    background-color: $white;
    box-shadow: 0 0 87px 0 rgba($black, 0.09);
  }
  .offcanvas-mobile-menu_header {
    padding: 0.8rem;
    box-shadow: 0 2px 15px 2px rgba($black, 0.05);
    .logo {
      height: 3rem;
      width: auto;
    }
  }
  &__content {
    padding: 0.5rem 1.5rem;
    @include respond(extra-small-mobile) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  &__navigation {
    & > ul {
      list-style: none;
      padding-left: 0;
      & > li {
        padding: 0.7rem 0;
        border-bottom: 1px solid rgba($grey, 0.4);
        &:last-child {
          border-bottom: none !important;
        }
        & > a {
          font-weight: 500;
          color: $button-text;
          text-decoration: none;
          &:hover {
            color: $orange;
          }
          &.active {
            color: $orange;
            border-bottom: 2px solid $orange;
          }
        }
      }
    }
  }
  .menu-expand {
    line-height: 50px;
    position: absolute;
    top: -15px;
    right: auto;
    left: 91%;
    width: 30px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    i {
      position: relative;
      display: block;
      width: 14px;
      margin-top: 35px;
      transition: all 250ms ease-out;
      border-bottom: 2px solid $blue;
      &::before {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        content: '';
        transform: rotate(90deg);
        border-bottom: 2px solid $blue;
      }
    }
  }
  .menu-item-has-children {
    a {
      text-decoration: none !important;
    }
    .service-main-menu {
      &.active {
        color: $orange;
        border-bottom: 2px solid $orange;
      }
    }
    position: relative;
    &.active {
      i::before {
        transform: rotate(0);
      }
    }
  }
  .submenu-wrapper {
    position: relative;
    .sub-menu-expand {
      line-height: 50px;
      position: absolute;
      top: -15px;
      right: auto;
      left: 91%;
      width: 30px;
      height: 50px;
      cursor: pointer;
      text-align: center;
      i {
        position: relative;
        display: block;
        width: 14px;
        margin-top: 35px;
        transition: all 250ms ease-out;
        border-bottom: 2px solid $blue;
        &::before {
          position: absolute;
          top: 0;
          display: block;
          width: 100%;
          content: '';
          transform: rotate(90deg);
          border-bottom: 2px solid $blue;
        }
      }
    }
  }
  .sub-menu-item-has-children {
    position: relative;
    &.active {
      i::before {
        transform: rotate(0);
      }
    }
  }
  ul {
    &.mobile-sub-menu {
      padding-left: 0;
      width: max-content;
      list-style: none;
      li:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        padding-right: 8px;
        background: $orange;
        margin-bottom: 2px;
      }
      li {
        font-size: 15px;
        color: $button-text;
        line-height: 20px;
        width: max-content;
        margin-bottom: 5px;
        a {
          color: $button-text;
          text-decoration: none;
        }
      }
    }
  }
}

.footer-section {
  z-index: 3 !important;
  position: relative;
  background-color: $button-text;
  .footer-upper {
    padding: 1rem 0;
    color: $white;
    a {
      text-decoration: none;
      font-size: 15px;
    }
    .footer-logo {
      font-size: 38px;
      line-height: 47px;
      font-weight: 600;
      width: max-content;
      color: $white;
      border-bottom: 3px solid $orange;
      &.quick-link {
        font-size: 27px;
        line-height: 38px;
        font-weight: 500;
      }
      @include respond(large-mobile) {
        font-size: 28px;
        line-height: 40px;
        &.quick-link {
          font-size: 25px;
          line-height: 32px;
        }
      }
    }
    .footer-items-wrapper {
      margin-top: 1rem;
      .contact-title-wrapper {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        .title {
          font-size: 20px;
          font-weight: 500;
          @include respond(large-mobile) {
            font-size: 16px;
          }
        }
      }
      .contact-item-icon-wrapper {
        margin-right: 10px;
        background-color: rgba($des-text, 0.25);
        box-shadow: 0px 3px 6px #00000029;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $orange;
        font-size: 25px;
        @include respond(large-mobile) {
          height: 25px;
          width: 25px;
          min-width: 25px;
          margin-right: 3px;
          font-size: 18px;
        }
      }
      .location-icon-wrapper {
        height: 40px;
        width: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        svg {
          height: 25px;
        }
        @include respond(large-mobile) {
          height: 25px;
          width: 25px;
          min-width: 25px;
          margin-right: 3px;
          svg {
            height: 18px;
          }
        }
      }
      .location-link a {
        color: rgba($white, 0.75) !important;
        &:hover {
          color: $orange !important;
        }
      }
      .quick-links {
        display: flex;
        flex-direction: column;
        a {
          color: rgba($white, 0.75) !important;
          margin-bottom: 1rem;
          &:hover {
            color: $orange !important;
          }
        }
      }
    }
  }
  .footer-bottom {
    border-top: 1px solid $orange;
    padding: 1rem 0;
    .footer-bottom-left {
      color: $white;
      a {
        color: $white;
        text-decoration: none;
      }
    }
    .footer-bottom-right {
      color: $orange;
      a {
        color: $orange;
        text-decoration: none;
      }
      @include respond(extra-large-mobile) {
        .justify-content-end {
          justify-content: center !important;
        }
      }
      @include respond(large-mobile) {
        .justify-content-end {
          justify-content: center !important;
        }
      }
      .col-md-auto {
        @include respond(extra-large-mobile) {
          width: max-content !important;
        }
        @include respond(large-mobile) {
          width: max-content !important;
        }
      }
    }
    // .social-icon-wrapper {
    //   background-color: $white;
    //   border-radius: 50%;
    //   height: 25px;
    //   width: 25px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // }
  }
}

.service-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  margin-bottom: 10px;
  p {
    color: $button-text;
    font-weight: 600;
    margin: 0 !important;
  }
  .line {
    width: 80%;
    height: 3px;
    border-top: 3px solid $orange;
  }
}

.header-button {
  background: $orange;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 12px 20px;
  color: $button-text;
  height: fit-content;
  box-shadow: 4px 4px 5px 1px rgba(#7a7a7a, 0.16);
  &:hover {
    background: $orange;
    color: $button-text;
  }
}
