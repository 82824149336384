.des-text {
  color: $des-text;
  font-size: 16px;
}
.item-title {
  font-size: 18px;
  color: $button-text;
  font-weight: 600;
}
.slick-slide {
  .maxWidths {
    max-height: 100% !important;
  }
}

.title-wrapper {
  display: flex;
  .orange-line {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-bottom: 4px solid $orange;
  }
  h2 {
    color: $button-text;
    font-size: 38px;
    font-weight: 700;
  }
  @include respond(extra-large-mobile) {
    .orange-line {
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-bottom: 3px solid $orange;
    }
    h2 {
      font-size: 36px !important;
    }
  }
  @include respond(large-mobile) {
    .orange-line {
      width: 30px;
      min-width: 30px;
      height: 30px;
      border-bottom: 3px solid $orange;
    }
    h2 {
      font-size: 30px !important;
    }
  }
}

.blue-bg-button {
  background: $button-text;
  border: none;
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 12px 20px;
  color: $white;
  height: fit-content;
  box-shadow: 4px 4px 5px 1px rgba(#7a7a7a, 0.16);
  &:hover {
    background: $button-text;
    color: $white;
  }
}

.orage-bg-whiote-text-button {
  background: $orange;
  border: none;
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 12px 20px;
  color: $white;
  height: fit-content;
  box-shadow: 4px 4px 5px 1px rgba(#7a7a7a, 0.16);
  &:hover {
    background: $orange;
    color: $white;
  }
}
.text-orage {
  color: $orange !important;
}

body {
  overflow-x: hidden;
  width: 100vw;
}
.error-text {
  color: red;
  font-size: 14px;
  line-height: 14px;
}
.policy-banner {
  padding: 2rem 0;
}
.policy-des {
  margin-left: 20px;
}
.policy-sec {
  h4 {
    color: $button-text;
  }
}
.justify-content-lg-center {
  @include respond(extra-large-mobile) {
    justify-content: center !important;
    margin-bottom: 2rem;
  }
  @include respond(large-mobile) {
    justify-content: center !important;
    margin-bottom: 2rem;
  }
}
