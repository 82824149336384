@-webkit-keyframes zooming {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zooming {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.zooming {
  -webkit-animation: zooming 3s infinite alternate backwards;
  animation: zooming 3s infinite alternate backwards;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotate-center {
  -webkit-animation: rotate-center 4s linear infinite both;
  animation: rotate-center 4s linear infinite both;
}
