$orange: #fd8008;
$blue: #184274;
$white: #ffffff;
$black: #222;
$text-color: #2f2e41;
$theme-color: #394759;
$button-text: #032555;
$des-text: #30517c;
$input-bg: #f5f2f2;

$grey: #7e7e7e;
$grey-one: #d1d6dc;
$grey--seven: #777777;
$grey--eight: #d0d0d0;
$theme-color--default: $black;
$transition--default: all 0.3s;
