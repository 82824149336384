.home-banner {
  padding: 2rem 0 0 0;
  background: transparent linear-gradient(180deg, #ffffff29 0%, #fd80081c 52%, #30517c24 100%) 0% 0% no-repeat
    padding-box;
  h1 {
    color: $orange;
    font-weight: 700;
    margin-top: 5rem;
    @include respond(extra-large-mobile) {
      margin-top: 3rem;
    }
    @include respond(small-mobile) {
      margin-top: 2rem;
    }
  }
  .banner-right-col {
    position: relative;
    display: flex;
    justify-content: center;
    img {
      max-height: 25rem;
    }
    .shape-1 {
      position: absolute;
      bottom: 4rem;
      right: 0;
    }
    .shape-2 {
      position: absolute;
      bottom: 2rem;
      left: -4rem;
    }
    .shape-3 {
      position: absolute;
      top: 2rem;
      left: 1rem;
    }
  }
}

.platform-sec {
  padding: 1rem 0;
  .platform-card {
    height: 150px;
    padding: 0 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.what-we-do-sec {
  .we-do-itm-wrapper {
    display: flex;
    margin-bottom: 10px;
    .we-do-image-wrapper {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      width: 100px;
      min-width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-right: 15px;
      img {
        max-width: 60px;
        max-height: 60px;
        height: 60px;
        width: auto;
      }
    }
  }
}
.our-milestone {
  padding: 2rem 0;
  .milestone-itm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 27px;
    padding: 0 20px 10px 20px;
    .orange-line {
      width: 60%;
      height: 4px;
      border-top: 4px solid $orange;
      margin-bottom: 15px;
    }
  }
}

.industry-expertise {
  padding: 2rem 0 0 0;
}

.industries-carousal-wrapper {
  padding-bottom: 3rem;
  background: linear-gradient(180deg, $white 27.99%, $button-text 28%, $button-text 100%) !important;
  h3 {
    color: $white;
    margin-bottom: 2rem;
  }
  img {
    max-height: 15rem;
  }
  .slick-prev,
  .slick-next {
    top: 70%;
    &::before {
      content: '';
    }
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
  .feature-wrapper {
    font-size: 16px;
    color: $white;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .fet-dot {
      background-color: $orange;
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }
    p {
      margin: 0;
    }
  }
  @include respond(extra-large-mobile) {
    background: linear-gradient(180deg, $white 17.99%, $button-text 18%, $button-text 100%) !important;
    img {
      max-height: 10rem !important;
    }
  }
  @include respond(large-mobile) {
    background: linear-gradient(180deg, $white 10.99%, $button-text 11%, $button-text 100%) !important;
    img {
      max-height: 8rem !important;
    }
    h3 {
      font-size: 16px !important;
      margin-bottom: 0.5rem !important;
    }
    .slick-prev,
    .slick-next {
      top: 100%;
      &::before {
        content: '';
      }
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
  }
}
.ultimate-exp {
  img {
    width: 120%;
    max-width: 120%;
    margin-left: -10%;
    @include respond(large-mobile) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
    @include respond(extra-large-mobile) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }
}

.estimate-cost {
  padding: 2rem 0;
  background-color: $button-text;
  .title-wrapper h2 {
    color: $white;
  }
  .des-text {
    color: $grey-one;
  }
}
.explore-case-studies {
  padding: 2rem 0;
  .case-study-itm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    img {
      max-width: 70%;
    }
  }
  .case-study-title-wrapper {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    padding: 10px 15px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      text-align: center;
    }
    .orange-line {
      width: 50%;
      height: 4px;
      border-top: 4px solid $orange;
    }
  }
}

.project-card {
  margin: 0 10px;
  box-shadow: 0px 3px 6px #00000029;
  .project-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    img {
      height: 3rem;
      cursor: pointer;
    }
  }
}
.client-testimonials {
  padding: 2rem 0;
  .testi-card {
    margin: 4rem 2rem 2rem 2rem;
    background: transparent linear-gradient(180deg, #fd800836 0%, #ffffff59 50%, #30517c1c 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem 2rem 2rem;
  }
  .testi-img-wrapper {
    margin-top: -4rem;
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    border: 5px solid $white;
    box-shadow: 0px 3px 6px #00000029;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
  }
  .testi-name {
    margin: 2rem 0;
    color: $orange;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  .testi-detail-wrapper {
    color: $des-text;
    line-height: 30px;
    svg {
      height: 30px;
      width: 30px;
    }
  }
  @include respond(large-mobile) {
    .mb-5 {
      margin-bottom: 1rem !important;
    }
    .testi-card {
      margin: 4rem 0 0 0;
      padding: 0 10px 10px 10px;
      border-radius: 25px;
    }
    .testi-img-wrapper {
      margin-top: -3rem;
      img {
        height: 8rem;
      }
    }
    .testi-name {
      margin: 1rem 0;
      font-size: 18px;
    }
    .testi-detail-wrapper {
      line-height: 22px;
      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
}
.certificate-sec {
  padding: 2rem 0;
  .certificate-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 25px #00000029;
    margin: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
    width: 12rem;
    img {
      height: 8rem;
      width: auto;
    }
  }
}
.achieve-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 25px #00000029;
  border-radius: 60px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  width: 14rem;
  img {
    height: 10rem;
    width: auto;
  }
}
.newsletter-sec {
  padding: 2rem 0 3rem 0;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 49.99%, #032555 50%, #032555 100%);
  .orange-bg-box {
    box-shadow: 0px 3px 25px #00000029;
    background-color: $orange;
    padding: 2rem;
    border-radius: 30px;
    h2 {
      color: $button-text;
      font-size: 40px;
      font-weight: 700;
    }
    p {
      color: $button-text !important;
    }
  }
  .news-letter-form-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 49px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      border-radius: 50px;
      padding: 12px 20px;
      width: 100%;
      margin-right: 15px;
    }
    .error-text {
      margin-left: 15px;
    }
    button {
      background: $button-text;
      border: none;
      box-sizing: border-box;
      border-radius: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
      padding: 12px 30px;
      color: $orange;
      height: fit-content;
      box-shadow: 0px 3px 6px #00000029;
      &:hover {
        background: $button-text;
        color: $white;
      }
    }
  }
  position: relative;
  .newsHand {
    height: 8rem;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 15%;
    @include respond(extra-large-mobile) {
      height: 5rem;
    }
    @include respond(large-mobile) {
      height: 5rem;
    }
  }
}
