.industry-sec-wrapper {
  position: relative;
  &.is-reverse {
    flex-direction: row-reverse;
  }
  h2 {
    z-index: 3;
  }
  .bg-vector {
    transform: rotate(200deg);
    position: absolute;
    z-index: 1;
    top: -10%;
    height: 120%;
    @include respond(large-mobile) {
      display: none;
    }
    @include respond(extra-large-mobile) {
      max-width: 100vw;
    }
  }
  .sec-image {
    position: relative;
    z-index: 2;
    max-height: 25rem;
  }
}

.about-us-banner {
  position: relative;
  padding-bottom: 3rem;
  .bg-vector {
    transform: rotate(90deg);
    position: absolute;
    z-index: 1;
    top: -10%;
    height: 120%;
    @include respond(extra-large-mobile) {
      display: none;
    }
    @include respond(large-mobile) {
      display: none;
    }
  }
  .sec-image {
    position: relative;
    z-index: 2;
    max-height: 25rem;
  }
}

.about-us-content {
  position: relative;
  padding-bottom: 3rem;
  .title-wrapper {
    margin-bottom: 3rem;
  }
  .title-wrapper,
  .des-text {
    z-index: 2;
    position: relative;
  }
  .bg-vector {
    // transform: rotate(90deg);
    position: absolute;
    z-index: 1;
    left: 10%;
    top: 0;
    width: 80%;
    height: 40rem;
  }
}
.portfolio-cards {
  padding: 0 0 3rem 0;
  .port-card {
    background: #efeeee;
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 1rem;
    img {
      height: 20rem;
    }
  }
}
