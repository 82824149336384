.service-banner {
  position: relative;
  padding: 3rem 0 1rem 0;
  .title-wrapper h2 {
    font-size: 35px;
  }
  .image-wrapper-col {
    position: relative;
    display: flex;
    justify-content: center;
  }
  @include respond(extra-large-mobile) {
    .image-wrapper-col {
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
  @include respond(large-mobile) {
    .image-wrapper-col {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
  .banner-bg-vector {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 125%;
    max-width: 100vw;
    @include respond(large-mobile) {
      display: none;
    }
    @include respond(extra-large-mobile) {
      height: 25rem;
      left: 30%;
      bottom: 3rem;
    }
  }
  .serviceImg {
    max-width: 100%;
    position: relative;
    z-index: 2;
    @include respond(large-mobile) {
      max-height: 20rem !important;
      max-width: 100vw;
    }
    @include respond(extra-large-mobile) {
      max-height: 20rem !important;
      max-width: 100vw;
    }
  }
}

.service-sec-two {
  padding: 2rem 0;
  .serviceImg {
    width: 100%;
    height: auto;
    @include respond(large-mobile) {
      width: 90%;
    }
    @include respond(extra-large-mobile) {
      width: 90%;
    }
  }
}

.service-features {
  padding: 2rem 0 3rem 0;
  .title-wrapper h2 {
    font-size: 37px;
  }
  .feature-itm-wrapper {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 27px;
    padding: 0 20px 10px 20px;
    height: 100%;
    .orange-line {
      width: 60%;
      height: 4px;
      border-top: 4px solid $orange;
      margin-bottom: 15px;
    }
    .fet-title {
      color: $orange;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 17px;
      &.isFromAdword {
        width: 100%;
        color: $button-text !important;
        text-transform: none;
        text-align: left;
      }
    }
    .image-wrapper {
      width: 70%;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      img {
        max-width: 100%;
        max-height: 6rem;
      }
    }
  }
}

.here-to-help {
  img {
    width: 80%;
    height: auto;
  }
}
.services-blue-bg {
  padding: 3rem 0;
  background-color: $button-text;
  .title-wrapper h2 {
    color: $white;
    font-size: 35px;
  }
  .des-text {
    color: $grey-one;
  }
  .sec-image {
    max-height: 20rem;
  }
  .is-reverse {
    flex-direction: row-reverse;
  }
  .feature-wrapper {
    font-size: 16px;
    color: $grey-one;
    display: flex;
    margin-bottom: 5px;
    .fet-dot {
      background-color: $orange;
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 6px;
    }
    p {
      margin: 0;
    }
  }
}
.services-faqs {
  padding: 3rem 0;
  .faq-itm-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 15px;
    padding: 20px 15px;
  }
  .faq-content-wrapper {
    h4 {
      font-size: 20px;
      font-weight: 700;
    }
    p {
      margin-bottom: 0 !important;
    }
    .feature-wrapper {
      font-size: 16px;
      color: $des-text;
      display: flex;
      margin-top: 5px;
      .fet-dot {
        background-color: $orange;
        height: 12px;
        width: 12px;
        min-width: 12px;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 6px;
      }
      p {
        margin: 0;
      }
    }
  }
  .faq-icon-wrapper {
    margin-left: 5px;
    height: 45px;
    min-height: 45px;
    width: 45px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 50%;
    font-size: 20px;
    &.active {
      background-color: $orange;
    }
    &.inactive {
      background-color: rgba($orange, 0.4);
    }
  }
}

.service-horizontal-features {
  padding: 2rem 0 3rem 0;
  .title-wrapper h2 {
    font-size: 37px;
  }
  .is-reverse {
    flex-direction: row-reverse;
  }
  .feature-itm-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 20px;
    padding: 20px 20px 20px 0;
    height: 100%;
    .orange-line {
      width: 4px;
      height: 6rem;
      border-left: 4px solid $orange;
      margin-right: 15px;
    }
    .fet-title {
      color: $orange;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 17px;
      &.isFromAdword {
        width: 100%;
        color: $button-text !important;
        text-transform: none;
        text-align: left;
      }
    }
    .image-wrapper {
      width: 8rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      img {
        max-width: 100%;
        max-height: 8rem;
      }
    }
    .des-text {
      margin: 0 !important;
    }
  }
}

.service-key-benefits {
  padding: 2rem 0;
  .title-wrapper h2 {
    font-size: 37px;
  }
  .feature-itm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #03255563;
    border-radius: 10px;
    padding: 2rem;
    height: 100%;
    .fet-title {
      color: $orange;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 17px;
    }
    .des-text {
      text-align: center;
      margin: 0 !important;
    }
  }
}
